<template>
    <el-dialog title="分配资金" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
        <el-table :data="allotFrom" border stripe style="width: 100%" show-summary>
            <el-table-column label="序号" align="center" width="65" type="index" />
            <el-table-column label="分配金额" prop="allotMoney" />
            <el-table-column label="下月费用备注" prop="attributionName" />
            <el-table-column label="下个月预估费用" prop="planMoney" />
            <el-table-column label="下月总计" prop="nextValue" />
            <el-table-column label="处理原因" prop="disposeRemark" />

        </el-table>

    </el-dialog>
</template>
<script>
import { list, } from "@/api/capital/bank/bankAllot";
import {
    selectSalaryByIdV1,
} from "@/api/work/salary/salaryDetails";
import {
    salaryDetailsList,
} from "@/api/work/salary/salaryDetails";
export default {
    name: "allotShow",
    data() {
        return {
            dialogVisible: false,
            allotFrom: [],
            dataList: [],
            attributionKeyList: [],
            version: ''
        };
    },
    methods: {
        //打开弹窗
        show(id, allotMonye) {
            this.dialogVisible = true;
            this.listButton(id);
            this.attributionKeyList = this.$parent.attributionKeyList
            this.getSalaryDetails();
        },
        listButton(id) {
            list({ id: id }).then(res => {
                this.allotFrom = res.result;
            });
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then(_ => {
                    done();
                    this.$parent.getBankList()
                })
                .catch(_ => { });
        },
        showLabelCat(row) {
            var label = '----';
            let foundItem = this.attributionKeyList.find(item => item.key === row);
            if (foundItem != undefined) {
                label = foundItem.label
            }
            return label;
        },
        getSalaryDetails() {
            salaryDetailsList(this.queryParams).then((res) => {
                this.version = res.result.records[0].version;
                this.selectSalaryByButton(this.version);
            });
        },
        selectSalaryByButton(version) {
            selectSalaryByIdV1({ version: version }).then((res) => {
                this.dataList = [res.result.fixDataList, res.result.improveDataList, res.result.otherDataList, res.result.lifeDataList].reduce((acc, cur) => acc.concat(cur), []);
                this.buildDataList()
            });
        },
        buildDataList() {
            var newData = this.allotFrom.map(obj => {
                let foundItems = this.dataList.filter(item => item.attributionKey === obj.category);
                if (foundItems != undefined) {
                    let totalValue = foundItems.reduce((sum, item) => sum + item.planMoney, 0);

                    let attributionName = foundItems.map(item => item.targetName).join(',');
                    return { ...obj, nextValue: totalValue + obj.allotMoney, planMoney: totalValue,attributionName: attributionName };
                }
                return obj;
            });
            this.allotFrom.splice(0, this.allotFrom.length, ...newData);
            console.log('new', this.allotFrom);
        }
    }
};
</script>